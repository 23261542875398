export const migrations = {
  0: (oldState: any) => {
    return {
      ...oldState,
      auth: {
        isLoading: false,
        isSigned: false,
        isAuthenticationExpired: false,
        headers: {
          uid: '',
          client: '',
          accessToken: '',
          tokenType: '',
          expiry: '',
        },
      },
      user: {
        currentUser: { ...oldState.user.currentUser },
      },
    }
  },
  1: (oldState: any) => {
    return {
      ...oldState,
      auth: {
        isLoading: false,
        isSigned: false,
        isAuthenticationExpired: false,
        headers: {
          uid: '',
          client: '',
          accessToken: '',
          tokenType: '',
          expiry: '',
        },
      },
      user: {
        ...oldState.user,
        currentUser: null,
      },
    }
  },
}
